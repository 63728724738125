/* The Modal (background) */
.modall {
    /* position: fixed;Stay in place */
    position: fixed;
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 100px;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    /* background-color: rgb(0, 0, 0); Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /*Black w/ opacity*/
}

/* Modal Content (image) */
.modal-content {
    margin: auto;
    display: block;
    /* width: 50%; */
    object-fit: contain;
    max-height: 70vh;

}

/* Add Animation */
.modal-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0);
    }

    to {
        -webkit-transform: scale(1);
    }
}

@keyframes zoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

/* The Close Button */
.close {
    position: absolute;
    top: 30px;
    right: 35px;
    color: #f1f1f1;
    font-size: 50px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 800px) {
    .modal-content {
        margin-top: 100px;
        width: 100%;
    }
    .newYearGala video {
        height: 300px;
    }
}


/* .galleryMore {
    text-align: center;
    font-size: 2rem;
} */

