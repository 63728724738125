.gallery {
    position: relative;
    /* display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly; */

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    justify-items: center;
    grid-column-gap: 10px;
    column-gap: 10px;

}

.galleryImage {

    border-radius: 10px;
    /* object-fit: contain; */
    object-fit: cover;
    margin-top: 20px;
    margin-bottom: 20px;
}


.newYearGala a:link { 
    text-decoration: none; 
    color: black;
  } 
  .newYearGala a:visited { 
    text-decoration: none; 
  } 
.newYearGala a:hover { 
    text-decoration: underline; 
  }  
  


ul img {
	width: 100%;
	display: block;
	aspect-ratio: 1 / 1;
	object-fit: cover;
	transition: transform 1000ms;
	padding: 20px;
}

ul{
	list-style: none;
	margin: 0;
	padding: 0;
	display: grid;
	gap: 0.5rem;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	max-width: 100%;
	/* width: 70rem; */
}
ul button{
	border: none;
}
button:is(:hover, :focus) img {
    transform: scale(1.15);
}